.dash-main {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    padding: 0.5rem;
    width: max-content;
    color: #075e54;
    text-align: center;
}
.dash-header {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 5rem;
}
.dash-qrcode {
    padding: 0.5rem;
    background-color: white;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}
.dash-form > .form-check-label > .form-check-input {
    width: 1rem;
    height: 1rem;
}
.dash-terms-of-use-link {
    margin-left: 0.4rem;
    font-size: 1.2rem;
    color: #075e54;
}
#dash-login-is-loader {
    width: 130px;
    height: auto;
    top:10%;
    right: 50%;
}
.finished-successfully-line1 {
    color: rgb(72, 197, 72);
    font-size: 40px;
}
.finished-successfully-line2 {
    color: white;
    font-size: 40px;
}
.dash-qrcode-div {
    margin-top: 5rem;
}
.dash-finished-successfully-main {
    margin-top:3%;
}
.dash-no-match-main {
    margin-top: 15%;
    font-size: 1.5rem;
    margin-right: 20%;
    margin-left: 20%;
}
.dash-no-match-text {
    text-align: center;
    color: #075e54;
}
.dash-no-match-buttons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    margin-top: 2rem;
}
.dash-double-entrance-main {
    margin-top: 50%;
    font-size: 1.5rem;
}
.dash-logout-btn-div {
    margin-right: 10px;
    margin-top: 10px;
}
.dash-logout-btn {
    margin-left: auto;
    background-color: #cfe9ba;
    color: #075e54;
}
#dash-ok-btn {
    background-color: #25d366;
    color: white;
    margin-left: 3rem;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    border: unset;
}
#dash-cancel-btn {
    background-color: #075e54;
    border: unset;
}