.old-msg-dd-main {
    margin-top: 3%;
}
.old-msg-dd-explanation {
    margin-top: 3%;
    margin-right: 20%;
    margin-left: 20%;
    color: #075e54;
    text-align: center;
    font-size: 1.5rem;
}
.old-msg-dd-dropdown {
    margin-top: 3%;
}
.old-msg-dd-dropdown-toggle {
    min-width: 200px;
    background-color: #cfe9ba;
    color: #075e54;
}
.old-msg-dd-choose-time-text {
    margin-top: 5%;
    font-size: 1.2rem;
    color: #075e54;
}
.old-msg-dd-choose-time-submit-btn {
    margin-top: 3%;
    background-color: #cfe9ba;
    color: #075e54;
}
.old-msg-dd-retrieving-msg {
    margin-top: 15%;
}
.old-msg-dd-retrieving-msg-text {
    color: #075e54;
    text-align: center;
    font-size: 1.5rem;
}
.old-msg-dd-retrieving-msg-spinner {
    margin-top: 2%;
}