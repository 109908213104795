#admin-screen-spikko-sessions-btn, 
#admin-screen-disconnected-btn,
#admin-screen-logout-btn,
#admin-screen-spikko-keep-alive-btn,
#admin-screen-send-msg-btn,
#admin-screen-spikko-keep-alive-btn-create-in-addition {
    background-color: #cfe9ba;
    color: #075e54;
}
.admin-screen-table-main {
    margin: 14px;
}
.admin-screen-table {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}
.admin-screen-btn-div {
    display: flex;
    width: 96%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}
.admin-screen-spikko-sessions-btn,
.admin-screen-logout-btn,
.admin-screen-spikko-keep-alive-btn {
    margin-left: 10px;
}
.admin-screen-no-data-msg {
    color: #075e54;
}
.admin-screen-delete-btn {
    margin-top: 10px;
    margin-right: 10px;
}
.admin-screen-btn-user-name {
    font-size: 18px;
    font-weight: bold;
}
.admin-screen-btn-user-name-header {
    font-size: 18px;
    font-weight: 500;
}
.admin-screen-status-changed {
    background-color: none;
    animation-name: changeColor1;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
@keyframes changeColor1 {
    from {background-color: none;}
    to {background-color: #34B7F1;}
}
.admin-screen-no-restart {
    background-color: none;
    animation-name: changeColor2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
@keyframes changeColor2 {
    from {background-color: none;}
    to {background-color: #128C7E;}
}
.admin-screen-no-available {
    background-color: none;
    animation-name: changeColor3;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
@keyframes changeColor3 {
    from {background-color: none;}
    to {background-color: #075E54;}
}
.admin-screen-close-notification-relative {
    position: relative;
}
.admin-screen-close-notification-absolute {
    position: absolute;
    left: 77%;
    bottom: 0%;
    border: 1px solid black;
    width: 30px;
    height: 30px;
    border-radius: 25px;
    padding-right: 7px;
    padding-top: 4px;
    cursor: pointer;
}
.status-color {
    background-color: #34B7F1;
}
.restart-color {
    background-color: #128C7E;
}
.available-color {
    background-color: #075E54;
}
.admin-screen-notification-reason {
    animation-name: changeTextColor;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
@keyframes changeTextColor {
    from {color: transparent;}
    to {color: #000000;}
}
.admin-screen-send-icon {
    margin-left: 10px;
}
.admin-screen-logout-icon {
    margin-left: 8px;
    height: 25px;
    width: 25px;
}
.admin-screen-ka-form-modal {
    max-width: 500px;
}
.admin-screen-spikko-keep-alive-ml-auto {
    margin-left: auto;
}
.admin-screen-edit-ka {
    background-color: #cfe9ba;
    color: #075e54;
    margin-left: 10px;
}
.admin-screen-buttons-group {
    display: flex;
    align-items: center;
    margin-left: auto;
}