#admin-screen-reload-btn {
    background-color: #cfe9ba;
    color: #075e54;
}
.admin-screen-reload-icon {
    margin-left: 6px;
}
.rotate {
    animation: rotation 1s;
} 
.linear {
    animation-timing-function: linear;
}
.infinite {
    animation-iteration-count: infinite;
}  
@keyframes rotation {
    from {
        transform: rotate(0deg);
    } to {
      transform: rotate(359deg);
    }
}